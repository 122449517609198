import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  handleChange: Function;
  formValues: any;
  handleNext: Function;
}

const Step3 = ({ handleChange, handleNext, formValues }: Props) => {
  return (
    <div>
      <form onSubmit={() => handleNext()}>
      <div className={styles.inputContainer}>
        <h3>Information required for CCB</h3>
        <p>To ensure that you are linked through the Child Care Management System (CCMS)
          and have Child Care Benefits (CCB) applied to your child care fees;
          you need to complete the section below.
          Please ensure the birth date of both parent and child is correct.
          We require the CRN number for both child and parent. These numbers are unique to each person.
        </p>
        <div className={styles.inner}>
          <label>
            Parent/Guardian Surname
            <input
              name="CCBParentGuardianSurname"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.CCBParentGuardianSurname || ''}
              required
            />
          </label>
          <label>
            Parent/Guardian First Name
            <input
              name="CCBParentGuardianFirstName"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.CCBParentGuardianFirstName || ''}
              required
            />
          </label>
          <label>
            Date of Birth
            <input
              name="CCBDOB"
              type="date"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.CCBDOB || ''}
              required
            />
          </label>
          <label>
            CRN
            <input
              type="text"
              name="COBCrn"
              value={formValues && formValues.CCBCrn|| ''}
              onChange={(e) => handleChange(e)}
              required
            />
          </label>
          <label>
            Medicare Number
            <input
              name="COBMedicare"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.COBMedicare|| ''}
              required
            />
          </label>
          <h3 className={styles.fullWidth}>Emergency/Additional Authorised Nominees</h3>
          <p>
            In case of an emergency, Albany OSHC will contact the parents/guardian
            initially. If contact is unsuccessful, we will contact the following people,
            in the order that they are listed.
          </p>
          <p>
            <span>
              <strong>Authorised nominee</strong>&nbsp;
              means a person who has been given permission by a parent or family member to collect the child from the education
              and care service or the family day care educator. See section 170(5) of the Law.
            </span>
          </p>
          <div className={styles.fullWidth}><strong>Contact 1</strong></div>
          <label>
            Full Name
            <input
              name="Emergency1Name"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Name || ''}
              required
            />
          </label>
          <label>
            Relationship to Child
            <input
              name="Emergency1Relationship"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Relationship || ''}
              required
            />
          </label>
          <label>
            Home Address
            <input
              name="Emergency1Address"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Address || ''}
              required
            />
          </label>
          <label>
            Mobile
            <input
              name="Emergency1Mobile"
              type="number"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Mobile || ''}
              required
            />
          </label>
          <div className={styles.radioGroup}>
            <strong style={{ fontSize: '0.8rem'}}>This nominee is also authorised to perform the following:</strong>
          </div>
          <label className={styles.fullWidth}>
            Collect the child in an emergency
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency1CanCollect" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency1CanCollect" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
            Authorised to consent to medical treatment of and including transportation by ambulance
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency1ConsentMedical" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency1ConsentMedical" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
            Authorise administration of medication to, the child
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency1AuthoriseMedication" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency1AuthoriseMedication" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
            Authorise for occasions for an educator to take child off the premises including excursions
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency1AuthoriseExcursion" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency1AuthoriseExcursion" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>

          <div className={styles.fullWidth}><strong>Contact 2</strong></div>
          <label>
            Full Name
            <input
              name="Emergency2Name"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Name || ''}
              required
            />
          </label>
          <label>
            Relationship to Child
            <input
              name="Emergency2Relationship"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Relationship || ''}
              required
            />
          </label>
          <label>
            Home Address
            <input
              name="Emergency2Address"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Address || ''}
              required
            />
          </label>
          <label>
            Mobile
            <input
              name="Emergency2Mobile"
              type="number"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.Emergency1Mobile || ''}
              required
            />
          </label>
          <div className={styles.radioGroup}>
            <strong style={{ fontSize: '0.8rem'}}>This nominee is also authorised to perform the following:</strong>
          </div>
          <label className={styles.fullWidth}>
            Collect the child in an emergency
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency2CanCollect" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency2CanCollect" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
            Authorised to consent to medical treatment of and including transportation by ambulance
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency2ConsentMedical" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency2ConsentMedical" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
            Authorise administration of medication to, the child
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency2AuthoriseMedication" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency2AuthoriseMedication" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
            Authorise for occasions for an educator to take child off the premises including excursions
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="Emergency2AuthoriseExcursion" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="Emergency2AuthoriseExcursion" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <h3>Information required for CCB</h3>
          <label className={styles.fullWidth}>
            Are there any court orders relating to the powers and responsibilities of the parents in relation to the child or access to the child?
            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="CourtOrderParents" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="CourtOrderParents" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
          <label className={styles.fullWidth}>
              Details of any other court orders relating to child’s residence or contact with a parent or other persons?            <div className={styles.radioGroup}>
              <label>
                Yes
                <input type="radio" name="CourtOrderOthers" value="yes" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                No
                <input type="radio" name="CourtOrderOthers" value="no" onChange={(e) => handleChange(e)}  required />
              </label>
            </div>
          </label>
        </div>
        <p>Please provide court order information.</p>
        <p>
          Please note that educators at Albany OSHC will only permit people
          listed on the enrolment form to drop off or pick up your child.
          If someone else is dropping off or picking up your child and is not on the
          enrolment form please advise the centre as soon as possible,
          either by phone call or by writing a brief letter. If educators are unsure you
          will be contacted and identification may be requested from the parent/guardian.
        </p>
        <button type="submit" className={styles.nextBtn}>Next</button>
      </div>
      </form>
    </div>
  )
}
export default Step3;
